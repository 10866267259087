input {
    border: 1px solid #CCCCCC;
    border-radius: 16px;
    outline: none !important;
    font-size: 16px;
}

input:focus {
    border-color: #3486ce;
}

body {
    margin: 0!important;
    font-family: Roboto;
}